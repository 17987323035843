<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5">Send To Next Process</v-card-title>
        <v-divider class="ml-4 mr-5" />
        <v-card-text>
            <v-form v-model="valid" class="mb-2 mx-1">
                <v-row no-gutters>
                    <v-col cols="12" class="d-flex align-center">
                        <v-combobox
                            v-model="nextProcess"
                            hide-details
                            label="Destination Process *"
                            prepend-icon="mdi-tools"
                            :items="availableProcesses"
                            item-text="name"
                            :rules="[rules.required]"
                            required
                            class="mb-2"
                            :disabled="availableProcesses.length == 1"
                        />
                    </v-col>
                </v-row>
                <v-row no-gutters>
                    <v-col
                        cols="12"
                        xl="6"
                        lg="6"
                        md="6"
                        class="d-flex align-center"
                    >
                        <div class="d-flex align-center">
                            <v-icon class="mr-2">mdi-database-outline</v-icon>
                            <p class="ma-0">
                                Available Quantity: {{ availableQty }}
                            </p>
                        </div>
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-text-field
                            v-model="qtyToSend"
                            prepend-icon="mdi-database-export-outline"
                            label="Quantity To Send*"
                            type="number"
                            required
                            :rules="[rules.required, rules.minValue]"
                            class="mt-4 pa-0"
                            @change="validateQty"
                            :max="availableQty"
                        />
                    </v-col>
                </v-row>

                <div class="d-flex align-center mb-2">
                    <v-icon>
                        mdi-account-arrow-left
                    </v-icon>
                    <h3 class="ml-2">Receiving Operator</h3>
                </div>

                <v-row no-gutters>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-text-field
                            v-model="code"
                            type="password"
                            hide-details
                            label="User Code"
                            :rules="[rules.required]"
                            required
                            class="pl-1 pr-4 pt-1"
                        />
                    </v-col>
                    <v-col cols="12" xl="6" lg="6" md="6">
                        <v-text-field
                            v-model="confirmCode"
                            type="password"
                            hide-details
                            label="Confirm Code"
                            :rules="[rules.required, matchCode]"
                            required
                            class="pl-1 pt-1"
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions class="pb-3 pt-0">
            <v-btn text color="secondary" @click="close">
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" :disabled="!valid" @click="send">
                Send
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import API from '@/services/api'
import { mapMutations } from 'vuex'
import _ from 'lodash'
import cryptoJs from 'crypto-js'

export default {
    name: 'WOGeneralInfo',
    props: {
        item: {
            type: Object,
            required: true,
        },
        workOrder: {
            type: Object,
            required: true,
        },
        user: {
            type: Object,
            required: true,
        },
    },

    data: () => ({
        valid: false,
        loading: false,
        prevProcess: undefined,
        nextProcess: undefined,
        confirmCode: undefined,
        code: undefined,
        availableQty: 0,
        qtyToSend: undefined,
        processes: [],
        rules: {
            required: v => !!v || 'The value is required',
            minValue: v => v > 0 || 'The value must be greater than 0',
        },
        availableProcesses: [],
        readyForPackingProcess: {
            id: 'readyForPacking',
            name: 'Ready for packing',
        },
    }),
    async mounted() {
        this.item.processes.forEach(processId => {
            let process = this.item.dataProcesses.find(p => p.id == processId)
            this.processes.push(process)
        })
        this.availableProcesses = _.cloneDeep(this.processes)
        const processIndex = this.processes.findIndex(
            p => p.id == this.user.process.id
        )
        if (processIndex > -1) {
            this.prevProcess = this.processes[processIndex]
            if (processIndex + 1 < this.processes.length) {
                this.nextProcess = this.processes[processIndex + 1]
            }
            this.availableProcesses.splice(0, processIndex + 1)
            if (processIndex == this.processes.length - 1) {
                this.availableProcesses = [this.readyForPackingProcess]
                this.nextProcess = this.readyForPackingProcess
            } else if (processIndex == this.processes.length - 1) {
                this.availableProcesses = []
            }
        }
        this.calculateValues()
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        calculateValues() {
            try {
                let totalProduced = 0
                let totalDelivered = 0
                let totalReleased = 0
                if (this.nextProcess == this.readyForPackingProcess) {
                    if (this.item.quality) {
                        totalReleased = this.item.quality.reduce(
                            (total, register) =>
                                total + (Number(register.releaseQty) || 0),
                            0
                        )
                    }
                    if (this.item.deliveries) {
                        let delivered = this.item.deliveries.filter(
                            register =>
                                register.prevProcess.id == this.prevProcess.id
                        )
                        totalDelivered = delivered.reduce(
                            (total, register) => total + register.qty,
                            0
                        )
                    }
                    this.availableQty = totalReleased - totalDelivered
                } else {
                    if (this.item.production) {
                        let produced = this.item.production.filter(
                            register =>
                                register.process.id == this.prevProcess.id
                        )
                        totalProduced = produced.reduce(
                            (total, register) => total + register.qty,
                            0
                        )
                    }
                    if (this.item.deliveries) {
                        let delivered = this.item.deliveries.filter(
                            register =>
                                register.prevProcess.id == this.prevProcess.id
                        )
                        totalDelivered = delivered.reduce(
                            (total, register) => total + register.qty,
                            0
                        )
                    }
                    this.availableQty = totalProduced - totalDelivered
                }
            } catch (error) {
                console.error(error)
            }
        },
        close() {
            this.$emit('closeDialog')
        },
        matchCode() {
            return !!(
                this.code &&
                this.confirmCode &&
                this.code === this.confirmCode
            )
        },
        validateQty() {
            if (this.qtyToSend > this.availableQty) {
                this.qtyToSend = this.availableQty
            }
        },
        async send() {
            try {
                this.loading = true
                const encryptedCode = cryptoJs.AES.encrypt(
                    this.code,
                    process.env.VUE_APP_ENCRYPTION_PHRASE
                ).toString()
                this.code = encryptedCode
                this.confirmCode = this.code
                const items = await API.sendToNextProcess({
                    itemId: this.item.id,
                    workOrderId: this.workOrder.id,
                    qty: this.qtyToSend,
                    prevProcess: this.prevProcess,
                    nextProcess: this.nextProcess,
                    receivingSignCode: this.code,
                })
                this.$emit('closeDialog', items)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
</style>
